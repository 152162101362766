<template>
  <div class="app-container h">
    <div class="_card-box v" style="margin-right: 20px; width: 55%;">
      <div class="_header">商品列表</div>
      <div class="flex _body v">
        <div class="head-container wrap">
          <quick-select url="api/brand" v-model="query.brandId" filterable placeholder="品牌" class="filter-item" @change="toQuery" clearable style="width: 120px;" />
          <quick-cascader class="filter-item" v-model="query.seriesId" url="api/series/tree" :params="{enabled: true}" root-key @change="toQuery" check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 160px;" />
          <quick-cascader class="filter-item" v-model="query.categoryId" url="api/category/tree" @change="toQuery" filterable clearable placeholder="分类" expand-trigger="hover" style="width: 120px;" />
          <el-input v-model="query.keywords" clearable placeholder="商品编码/名称搜索" prefix-icon="el-icon-search" style="width: 180px;" class="filter-item" @keypress.enter.native="toQuery" />
          <el-select v-model="query.onShelf" placeholder="上架状态" style="width:100px" class="filter-item" clearable @change="toQuery">
            <el-option :key="1" label="上架" :value="true"></el-option>
            <el-option :key="2" label="下架" :value="false"></el-option>
          </el-select>
          <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
          <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
          <el-button type="primary" size="mini" class="filter-item" @click="uploadRetail" icon="el-icon-upload2">导入</el-button>
        </div>
        <el-table v-loading="loading" :data="data" size="small" highlight-current-row @current-change="handleCurrentChange" height="200">
          <el-table-column prop="code" label="商品编码" min-width="130" />
          <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
          <el-table-column label="商品名称" min-width="240">
            <div class="lh-150" slot-scope="scope">
              <div>{{scope.row.name}}</div>
              <div class="fc-g">{{$goodsSpecConvert(scope.row.specs)}}</div>
            </div>
          </el-table-column>
          <el-table-column key="shelf" prop="onShelf" label="上架" width="80" align="center">
            <i slot-scope="scope" :class="scope.row.onShelf && scope.row.enable ? 'el-icon-check' : ''"></i>
          </el-table-column>
          <el-table-column prop="retailPrice" label="统一零售价" align="right" width="120" :formatter="$price" />
        </el-table>

        <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
      </div>
    </div>

    <div class="_card-box v" style="margin-right: 20px; width: 55%;">
      <div class="_header h c sb">
        <span>统一零售价设置</span>
        <div>
          <el-radio-group v-model="setTab" style="margin: -10px 0;">
            <el-radio-button label="province">指定省份设置</el-radio-button>
            <el-radio-button label="area">指定地区设置</el-radio-button>
            <el-radio-button label="distributor">指定经销商设置</el-radio-button>
            <el-radio-button label="priceGroup">指定价格组</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <province-set key="province" class="flex _body v" :sku="current" v-if="setTab === 'province'" />
      <area-set key="area" class="flex _body v" :sku="current" v-else-if="setTab === 'area'" />
      <distributor-set key="distributor" class="flex _body v" :sku="current" v-else-if="setTab === 'distributor'" />
      <priceGroup-set key="group" class="flex _body v" :sku="current" v-else-if="setTab === 'priceGroup'" />
    </div>
    <retailForm ref="retailForm" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import ProvinceSet from "./province/list";
import AreaSet from "./area/list";
import DistributorSet from "./distributor/list";
import PriceGroupSet from "./priceGroupSet/list";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import retailForm from "./retail-import";

export default {
  mixins: [initData],
  components: {
    ProvinceSet,
    AreaSet,
    DistributorSet,
    PriceGroupSet,
    retailForm,
  },
  data() {
    return {
      downloadLoading: false,
      setTab: "province",
      current: null,
      query: {
        brandId: null,
        seriesId: null,
        categoryId: null,
        keywords: null,
        onShelf: true,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/seller/shop/salesGoods";
      this.params = this.query;
      return true;
    },
    handleCurrentChange(row) {
      this.current = row;
    },
    uploadRetail() {
      this.$refs.retailForm && this.$refs.retailForm.resetForm();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/shop/salesGoods/download", this.params)
        .then((result) => {
          downloadFile(result, "产品零售价信息", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>

<style lang="less">
._card-box {
  border-radius: 4px;
  border: 1px solid #e6ebf5;

  ._header {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: initial;
    position: relative;
    padding: 18px 20px;
    border-bottom: 1px solid #e6ebf5;
    font-size: 14px;
  }

  ._body {
    padding: 20px;
  }
}
</style>